.rightNav {
  background-color: var(--cstm-storm-light);
  border-left: 0.5px solid var(--cstm-storm-20);
  display: flex;
  align-items: flex-start;
  transition: 0.5s;

  &__isOpen {
    width: 29%;
    img {
      transform: none;
    }
  }
  &__isClose {
    img {
      transform: rotate(180deg);
    }
  }
  &__arrowBtn {
    padding: 1rem 0.375rem;
    height: 100%;
    display: flex;
    outline: none;
    border: none;
    background: none;
    border-right: 1px solid var(--cstm-storm-30);
  }
  &__children {
    background-color: var(--cstm-color-base);
    padding: 1.5rem 1rem;
    max-height: 90%;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
  }
}

@media (min-width: "1600px") {
  .rightNav__isOpen {
    width: 26%;
  }
}
