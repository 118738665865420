.breadcrumb {
  gap: 0.25rem;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  &__list {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    justify-content: center;
    &__item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &__bullet {
        width: 1rem;
        height: 1rem;
        border-radius: 100%;
        background-color: var(--cstm-sky-20);
      }
      &__line {
        flex-grow: 1;
        height: 0.25rem;
        width: 8rem;
        background-color: var(--cstm-sky-20);
      }

      &__active {
        &__line,
        &__bullet {
          background-color: var(--cstm-sky-key);
        }
        &__bullet {
          width: 0.75rem;
          height: 0.75rem;
          border: 2px solid var(--cstm-sky-20);
        }
      }
    }
  }
  &__title-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__label {
      font-size: 0.625rem;
      font-family: var(--family-roboto);
      color: var(--cstm-sky-key);

      &__active {
        font-family: var(--family-roboto-med);
      }
      &__before-active {
        color: var(--cstm-storm-40);
      }
    }
    &__label:nth-child(even) {
      margin-left: -7%;
    }
  }
}
