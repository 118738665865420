.date-picker-one {
  svg {
    path {
      stroke: var(--cstm-popup-black);
    }
  }
}

.date-picker-one .rmdp-container {
  width: 100% !important;
}
/* Box Calendar  */
.custom-container-one {
  transform: translate(-12px, 34px);
  box-shadow: 4px 7px 16px 0px #8798ad26 !important;
  padding: 0 20px;

  font-weight: bold;
  border-radius: 20px;
}

/* Box Title Year  */
.custom-container-one .rmdp-header {
  border-bottom: 1px solid #dfdfdfbd !important;
}

/* Text Title Year  */
.custom-container-one .rmdp-header-values {
  font-weight: bold !important;
  font-size: 18px !important;
  color: #828282 !important;
}
/* Spacing Columns  */
.custom-container-one .rmdp-week-day {
  padding: 5px !important;
  color: #000000 !important;
  font-weight: bold !important;
}

.date-picker-one,
input {
  font-family: "Roboto";
}

:root {
  --rmdp-primary-color-cstm: #007bff;
  --rmdp-secondary-color-cstm: #7272728a;
  --rmdp-shadow-color-cstm: none;
  --rmdp-today-color-cstm: #99caff;
  --rmdp-hover-color-cstm: #99caff;
  --rmdp-deselect-color-cstm: #99caff;
  --rmdp-range-color-cstm: #ecf2ff;
}

.color-cstm .rmdp-wrapper {
  border: 1px solid var(--rmdp-secondary-color-cstm);
  box-shadow: 0 0 5px var(--rmdp-secondary-color-cstm);
}

.color-cstm .rmdp-panel-body li {
  background-color: var(--rmdp-primary-color-cstm);
  box-shadow: 0 0 2px var(--rmdp-secondary-color-cstm);
}

.color-cstm .rmdp-week-day {
  color: var(--rmdp-primary-color-cstm);
}

.color-cstm .rmdp-day.rmdp-deactive {
  color: var(--rmdp-secondary-color-cstm);
}

.color-cstm .rmdp-range,
.color-cstm .rmdp-day.rmdp-range.rmdp-today span {
  background-color: var(--rmdp-range-color-cstm);
  box-shadow: 0 0 3px var(--rmdp-range-color-cstm);
  color: var(--rmdp-primary-color-cstm);
}
.color-cstm .rmdp-range.start,
.color-cstm .rmdp-range.end {
  background-color: var(--rmdp-primary-color-cstm);
  color: var(--cstm-color-base);
}

.color-cstm .rmdp-arrow {
  border: solid var(--rmdp-primary-color-cstm);
  border-width: 0 2px 2px 0;
}

.color-cstm .rmdp-arrow-container:hover {
  background-color: var(--rmdp-primary-color-cstm);
  box-shadow: 0 0 3px var(--rmdp-secondary-color-cstm);
}

.color-cstm .rmdp-panel-body::-webkit-scrollbar-thumb {
  background: var(--rmdp-primary-color-cstm);
}

.color-cstm .rmdp-day.rmdp-today span {
  background-color: var(--rmdp-today-color-cstm);
}
.color-cstm .rmdp-rtl .rmdp-panel {
  border-left: unset;
  border-right: 1px solid var(--rmdp-secondary-color-cstm);
}

.color-cstm .rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: var(--rmdp-primary-color-cstm);
  box-shadow: 0 0 3px var(--rmdp-shadow-color-cstm);
}

.color-cstm .rmdp-day:not(.rmdp-day-hidden) span:hover {
  background-color: var(--rmdp-hover-color-cstm) !important;
}

.color-cstm .b-deselect {
  color: var(--rmdp-deselect-color-cstm);
  background-color: white;
}

.color-cstm .rmdp-action-button {
  color: var(--rmdp-primary-color-cstm);
}

.color-cstm .rmdp-button:not(.rmdp-action-button) {
  background-color: var(--rmdp-primary-color-cstm);
}

.color-cstm .rmdp-button:not(.rmdp-action-button):hover {
  background-color: var(--rmdp-deselect-color-cstm);
}
.rmdp-week {
  margin-bottom: 0.25rem;
}
@media (min-width: 1600px) {
  .rmdp-week {
    margin-bottom: 0.625rem;
  }
}
@media (max-width: 1600px) {
  .custom-container-one .rmdp-header {
    padding: 0;
  }
}
