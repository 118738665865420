.pagination-one {
  .rc-pagination {
    display: flex;
  }
  .rc-pagination-item-active a,
  .rc-pagination-item a,
  .rc-pagination-item li {
    color: var(--cstm-color-base) !important;
    border: none !important;
    color: var(--cstm-popup-black) !important;

    border-radius: 10px !important;
  }

  .rc-pagination-item,
  .rc-pagination-jump-prev,
  .rc-pagination-prev,
  .rc-pagination-jump-next,
  .rc-pagination-next {
    background: var(--cstm-color-base) !important;
    margin: 0 4px !important;
    border-radius: 10px !important;
    border: 0 !important;
    color: var(--cstm-color-base) !important;
    padding: 3px !important;
    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: center;
  }

  .rc-pagination-next button {
    background: var(--cstm-color-base) !important;
    color: var(--cstm-sky-70) !important;
  }

  .rc-pagination-prev button {
    border-radius: 8px 0 0 8px !important;
    background: var(--cstm-color-base) !important;
    color: var(--cstm-sky-70) !important;
  }

  .rc-pagination-next button:hover,
  .rc-pagination-prev button:hover {
    border: none !important;
  }

  .rc-pagination-next button,
  .rc-pagination-prev button {
    border: none !important;
  }

  .rc-pagination-prev {
    background: var(--cstm-color-base) !important;
    border: none !important;
  }

  .rc-pagination-item-active {
    background: var(--cstm-sky-70) !important;
    border-radius: 10px !important;
    // box-shadow: 0 0 2vw 0.5vw #6e00f5;
  }
  .rc-pagination-item-active a {
    color: var(--cstm-color-base) !important;
  }

  .rc-pagination-jump-prev button,
  .rc-pagination-jump-next button {
    color: var(--cstm-oil-dark) !important;
  }
  .rc-pagination-prev {
    display: none;
  }
  .rc-pagination-prev,
  .rc-pagination-next,
  .rc-pagination-jump-prev,
  .rc-pagination-jump-next {
    svg {
      vertical-align: text-top;
      width: 13px;
      height: 13px;
      path {
        stroke: var(--cstm-sky-70);
      }
    }
  }
}
