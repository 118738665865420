.on-chain {
  box-sizing: border-box;
  width: 100%;
  background: var(--cstm-color-base);
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  &__container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding: 2.375rem 3.125rem;
  }

  &__box {
    width: 1300px;
    background: var(--cstm-color-base);
    display: flex;
    justify-content: space-between;

    &__left {
      border-radius: 10px;
      width: 40%;
      background: var(--cstm-storm-10);
      padding: 25px;
      box-sizing: border-box;
      &__title {
        color: var(--cstm-sky-70);
      }

      &__description {
        .write-something-cstm-one {
          height: 18rem;
          &__textarea {
            height: 100%;
          }
        }
      }
      &__submitButtons {
        display: flex;
        justify-content: right;
        gap: 1rem;
        margin-top: 1rem;
        width: 100%;
        button {
          width: 32.5%;
        }
      }
      &__errorBox {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-top: 0.5rem;
        &__errorItem {
          margin-bottom: 0.25rem;
          font-family: "Roboto";
          font-size: 14px;
          color: var(--cstm-infrared-key);
          text-align: right;
          overflow: auto;
        }
      }
    }
    &__right {
      width: 57%;
      gap: 15px;

      box-sizing: border-box;
      padding: 20px;
      padding-top: 3px;
      padding-bottom: 0;

      &__list-item {
        border-radius: 10px;
        box-shadow: 1px 1px 9px 0px rgba(155, 155, 155, 0.4392156863);
        height: 116px;
        box-sizing: border-box;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        &__left {
          display: flex;
          align-items: center;

          &__img {
            height: 92px;
            display: flex;
            border-radius: 10px;
            overflow: hidden;
            img {
              width: 100%;
            }
          }

          &__name {
            margin-left: 15px;
            color: var(--cstm-storm-key);
            font-family: var(--family-roboto);
            width: 200px;
          }
        }
      }
    }
  }
}
