.select-cstm-one {
  position: relative;
  border: 1px solid var(--cstm-storm-20);
  margin: 30px 0;

  border-radius: 16px;
  font-family: "Roboto";
  font-weight: bold;

  cursor: pointer;
  &__title {
    color: var(--cstm-oil-60);
    font-size: 11px;
    position: absolute;
    left: 12px;
    top: -6px;
    padding: 0 5px;
    background: var(--cstm-color-base);
  }

  &__show-value {
    padding: 16px;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: var(--cstm-oil-dark);

    &__icon-arrow {
      position: absolute;
      right: 17px;
      i {
        img {
          width: 14px;
        }
      }
    }

    &__selectedItems {
      padding: 0.375rem 0.625rem;
      background-color: var(--cstm-storm-20);
      border-radius: 1.25rem;
      color: var(--cstm-storm-50);
      margin-right: 0.625rem;
      display: flex;
      align-items: center;
      button {
        border: none;
        outline: none;
        background: none;
        cursor: pointer;
        margin: 0;
        padding: 0;
        display: flex;
      }
      svg {
        margin-right: 0.5rem;
        path {
          stroke: var(--cstm-oil-40);
        }
      }
    }

    &__icon-arrow {
      position: absolute;
      right: 17px;
      i {
        img {
          width: 14px;
        }
      }
    }
  }

  &__list {
    border: 1px solid var(--cstm-storm-20);
    position: absolute;
    border-radius: 16px;
    position: absolute;
    bottom: 6px;
    left: -1px;
    transform: translateY(calc(100% + 8px));
    background: var(--cstm-color-base);

    z-index: 1;
    width: 100%;
    overflow: hidden;

    &__item-box {
      display: flex;
      align-items: center;
      padding: 5px 0 5px 10px;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      font-size: 12px;
      overflow-x: hidden;
      li {
        padding: 16px;
        &:hover {
          background: var(--cstm-sky-20);
        }
      }
    }
  }
}

.input-box-cstm-one {
  border: 1px solid var(--cstm-storm-20);
  background: var(--cstm-color-base);
  border-radius: 12px;
  font-family: "Roboto";
  padding: 3px;
}
