.image-slider {

  .swiper {
    width: 100%;
    height: 270px;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: none;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    // align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    border-radius: 20px;
    object-fit: contain;
  }

  .swiper {
    margin-left: auto;
    margin-right: auto;
  }

  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    content: "next";
    font-size: 15px;
    font-weight: bold;
    background: #bbbbbb78;
    padding: 7px 11px;
    border-radius: 100%;
    color: #ffffff;
    border: 2px solid #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
    content: "prev";
    font-size: 15px;
    font-weight: bold;
    background: #bbbbbb78;
    padding: 7px 11px;
    border-radius: 100%;
    color: #ffffff;
    border: 2px solid #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
