.heatMap {
  // border: 1px dashed red;
  width: 90%;
  margin: 0 auto;
  margin-top: 50px;
  padding: 15px;
  overflow: hidden;

  &__new-heatMap-popup {
    transition: 1s;
    background: var(--cstm-popup-box);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    &__box {
      background: #ffffff;
      height: fit-content;
      border-radius: 0.625rem;
      display: flex;
      flex-direction: column;
      padding: 0 1.5rem 1.5rem 1.5rem;
      min-width: 35%;

      .select-cstm-one__show-value__icon-arrow svg {
        path {
          stroke: var(--cstm-sky-80);
        }
      }

      &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h5 {
          font-family: "RobotoMed";
          font-size: 18px;
          margin: 0;
          color: var(--cstm-sky-80);
        }
        .view-data-header-cstm__info {
          margin: 0;
        }
      }

      &__form {
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        &__select-cstm-ntfc {
          margin: 0;
          padding: 0.75rem;
          padding-left: 0;
          input {
            border: none;
            outline: none;
            background: none;
            width: 100%;
            font-family: "YekanBakh";
            text-align: right;
          }
        }

        &__description {
          border-radius: 10px;
          display: flex;
          padding: 5px;
          height: 113px;
          i {
            svg {
              width: 16px;

              path {
                stroke: var(--cstm-storm-40);
              }
            }
          }

          textarea {
            resize: none;
            width: 100%;
            border: none;
            outline: none;
            color: var(--cstm-oil-60);
            text-align: right;
            font-family: "YekanBakh";
            font-size: 14px;
            direction: rtl;
            &::placeholder {
              color: var(--cstm-storm-40);
              font-family: "Roboto";
              text-align: left;
            }
          }
        }
        &__add-cancel-btn {
          margin-top: 0.625rem;
        }
        .list-table-cstm-one {
          height: auto;
        }
      }
      .select-cstm-one {
        margin: 0;
      }
      &__errorBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 1rem;
        &__errorItem {
          margin-bottom: 0.25rem;
          font-family: "Roboto";
          font-size: 14px;
          color: var(--cstm-infrared-key);
          text-align: center;
          overflow: auto;
        }
      }
    }
  }
}
