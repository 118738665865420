.messageInput {
  display: flex;
  align-items: center;
  border: 1px solid var(--cstm-sky-30);
  background-color: var(--cstm-sky-10);
  margin-top: 1.25rem;
  border-radius: 2rem;
  padding: 0.25rem 0.375rem;
  input {
    width: 100%;
    border: none;
    outline: none;
    background-color: var(--cstm-sky-10);
    text-align: right;
    font-family: "yekanbakh";
    overflow-y: auto;
    direction: rtl;
  }
  ::placeholder {
    color: var(--cstm-storm-40);
    text-align: left;
  }
  button {
    border: none;
    outline: none;
    background: none;
    display: flex;
  }
}
