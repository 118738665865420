.privateNote {
  height: 100%;
  &__title {
    font-family: "Roboto";
    font-size: 0.875rem;
    font-weight: 400;
    color: var(--cstm-sky-70);
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin: 0;
    span {
      font-weight: 300;
      color: var(--cstm-storm-key);
      margin-left: 0.25rem;
    }
  }

  &__item {
    margin-top: 1rem;
    display: flex;
    align-items: flex-start;
    padding: 0.25rem 0.375rem;
    justify-content: space-between;
    svg {
      margin-right: 0.5rem;
      min-width: 1rem;
      height: 1rem;
      width: 1rem;
    }
    p {
      margin: 0;
      font-family: "yekanbakh";
      color: var(--cstm-storm-key);
      font-size: 0.875rem;
      text-align: right;
      overflow-wrap: anywhere;
    }
  }
  &__emptyList {
    &__title {
      padding: 0.5rem 0;
      margin: 0;
      text-align: center;
      font-family: "Roboto";
      font-size: 14px;
      text-align: left;
      color: var(--cstm-storm-key);
    }
  }
}
