.accordion {
  padding: 0 0.875rem;
  margin-bottom: 0.25rem;
  display: flex;
  flex-direction: column;
  align-items: normal;
  &__label {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 0.5rem;
    padding: 2px;
    justify-content: space-between;
    font-size: 0.875rem;
    font-weight: 400;
    font-family: "roboto";
    color: var(--cstm-sky-70);

    &__right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &__arrows {
        border-radius: 100%;
        width: 1.5rem;
        height: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0.5rem;
        cursor: pointer;
        &-arrowDown {
          background-color: var(--cstm-storm-30);

          svg path {
            stroke: var(--cstm-storm-80);
          }
        }
        &-arrowUp {
          background-color: var(--cstm-storm-80);

          svg path {
            stroke: var(--cstm-storm-40);
          }
        }
      }
    }

    &__left {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem 0;
    }
  }

  &-children {
    margin: 0.5rem 0 0.875rem;
  }
}
