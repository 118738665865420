

.title-cstm {
    border: 1px solid var(--cstm-storm-20);
    background: var(--cstm-color-base);
    border-radius: 12px;
    font-family: "Roboto";
    display: flex;
    align-items: center;
    padding: 11px;
    justify-content: space-between;
    &__name {
        font-family: var(--family-roboto-med);
        color: var(--cstm-oil-60);
    }
    input {
        border: none;
        width: 360px;
        outline: none;
        text-align: right;
        direction: rtl;
        font-family: var(--family-yekan-bakh);
        font-size: 15px;
        color: var(--cstm-oil-60);
    }
} 