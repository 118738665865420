.technical-view {
  // border: 1px solid red;
  display: flex;
  flex-direction: column;
  height: auto;
  padding-bottom: 30px;
  &__entries {
    margin-top: 1rem;
    padding: 0.625rem;
    background-color: var(--cstm-storm-light);
    display: flex;
    flex-direction: column;
    border-radius: 0.625rem;
    gap: 0.5rem;
    margin-top: 1rem;
    .accordion {
      padding: 0;
      &__label {
        background-color: var(--cstm-sky-10);
        padding: 0.375rem 0.625rem;
        border-radius: 0.625rem;
        &__right__arrows-arrowUp {
          background-color: var(--cstm-sky-20);
          svg path {
            stroke: var(--cstm-sky-key);
          }
        }
      }
      .technicalPointPreview__info__value {
        font-family: "robotoMed";
      }
      &-children {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin: 0.5rem 0 0;
      }
    }
    &__accordion {
      &__ep,
      &__tp {
        &__label {
          font-size: 0.875rem;
          font-weight: 700;
          display: flex;
          align-items: center;
          gap: 0.25rem;
          font-family: "robotoMed";
          &-count {
            color: var(--cstm-storm-key);
            font-weight: 300;
            font-family: "roboto";
          }
        }
      }
      &__ep {
        &__label {
          color: var(--cstm-sky-key);
        }
      }
      &__tp {
        &__label {
          color: var(--cstm-leaf-key);
        }
      }
    }
    &__sl {
      display: flex;
      .technicalPointPreview {
        width: 100%;
        &__info__value {
          font-family: "robotoMed";
          color: var(--cstm-storm-70);
        }
      }
    }
  }

}

.node::before {
  content: "";
  position: absolute;
  left: 0.25rem;
  top: 1.25rem;
  bottom: 0;
  width: 1px;
  background-color: var(--cstm-storm-40);
  z-index: 1;
}
