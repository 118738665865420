.description-cstm-one {
  &__box {
    border: 1px solid var(--cstm-storm-20);
    height: 150px;
    cursor: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    textarea {
      border: none;
      resize: none;
      width: 95%;
      height: 86%;
      outline: none;
      text-align: right;
      direction: rtl;
      font-family: var(--family-yekan-bakh);
      color: var(--cstm-oil-dark);
      font-size: 0.75rem;
    }
    &-limitationExceeded {
      border: 1px solid var(--cstm-infrared-50);
    }
  }

  &__limit-character-description {
    font-family: var(--family-roboto);
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 10px;
    svg {
      path {
        stroke: var(--cstm-fire-40);
      }
    }
  }
}
