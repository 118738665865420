.NotificationPointsSvg {
  vertical-align: middle;
  &__over {
    path {
      stroke: var(--cstm-leaf-50);
    }
  }
  &__under {
    path {
      stroke: var(--cstm-infrared-key);
    }
  }
}
