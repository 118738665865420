.write-something-cstm-one {
  display: flex;
  padding: 7px;
  gap: 6px;

  &__copy-icon {
    cursor: pointer;
    i {
      img {
        width: 16px;
      }
    }
  }

  &__textarea {
    width: 100%;
    height: 40px;
    padding-right: 8px;

    textarea {
      width: 100%;
      height: 100%;

      border: none;
      outline: none;
      font-family: "yekanbakh";
      resize: none;
      font-size: 12px;

      text-align: right;
      color: var(--cstm-oil-60);
      direction: rtl;
      &::placeholder {
        text-align: left;
      }
    }
  }
}
