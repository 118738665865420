.filter {
  width: 25%;
  background-color: var(--cstm-color-base);
  font-family: "roboto";
  font-size: 0.875rem;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin-left: -100%;
  transition: 1s;
  z-index: 1;

  &-isOpen {
    margin-left: 0%;
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    span {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    &__filterTitle {
      padding: 0.75rem;
      background-color: var(--cstm-sky-light);
      border-radius: 2rem;
      color: var(--cstm-sky-key);
      font-family: "robotoMed";

      img {
        margin-right: 0.5rem;
      }
    }

    button {
      margin-left: 0.625rem;
      outline: none;
      border: none;
      background-color: transparent;
      padding: 0;
      svg path {
        stroke: var(--cstm-sky-key);
      }
    }
  }
  &__appliedFilters {
    margin: 1rem 0;
    padding: 1rem;
    background-color: var(--cstm-storm-10);
    border-radius: 0.625rem;
    &__header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      &__title {
        color: var(--cstm-sky-70);
      }
      &__clear {
        color: var(--cstm-storm-40);
        background-color: transparent;
        border: none;
        outline: none;
        font-weight: 400;
        padding: 0;
        font-size: 0.875rem;
        font-family: "Roboto";
      }
    }

    &__items {
      display: flex;
      margin-top: 0.5rem;
      flex-flow: wrap;
      span {
        padding: 0.5rem 0.875rem;
        background-color: var(--cstm-sky-40);
        color: var(--cstm-oil-light);
        border-radius: 1.25rem;
        margin: 0.5rem 0.5rem 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      button {
        margin-left: 0.5rem;
        background: none;
        outline: none;
        border: none;
        padding: 0;
        display: flex;
        align-items: center;
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12.5rem;

    .accordion {
      width: 100%;
      padding-top: 0.5rem;
      &__label__right__arrows {
        &-arrowUp {
          background-color: var(--cstm-storm-30);
          svg path {
            stroke: var(--cstm-storm-80);
          }
        }
        &-arrowDown {
          background-color: var(--cstm-storm-80);
          svg path {
            stroke: var(--cstm-storm-40);
          }
        }
      }
    }

    &__field {
      border-top: 1px solid rgba(0, 0, 0, 0.1);

      &-totalSelected {
        margin-left: 0.25rem;
        color: var(--cstm-storm-key);
      }

      &__type {
        display: flex;
        flex-wrap: wrap;
        &-checkbox {
          display: none;
        }
        &-label {
          margin: 0 0.5rem 0.5rem 0;
          padding: 0.625rem 1.5rem;
          color: var(--cstm-oil-50);
          border: 2px solid var(--cstm-oil-20);
          border-radius: 1.25rem;
          &-isActive {
            color: var(--cstm-oil-light);
            border-color: var(--cstm-sky-40);
            background-color: var(--cstm-sky-40);
          }
        }
      }
      .select-cstm-one {
        margin: 0;
        margin-top: 0px;
        border-radius: 1.25rem;
        border: 1px solid var(--cstm-oil-40);
        &__show-value {
          padding: 0.5rem 0.75rem;
          color: var(--cstm-oil-50);
          font-size: 0.875rem;
          img {
            margin-right: 0.5rem;
          }
          &__icon-arrow {
            svg path {
              stroke: var(--cstm-oil-50);
            }
          }
        }
        &__list {
          z-index: 3;
          border-color: var(--cstm-storm-40);
          border-radius: 0.625rem;
          ul {
            height: 18.75rem;
            overflow-y: auto;
            margin: 0.5rem 2px 0.5rem 0;
            color: var(--cstm-oil-50);
            font-size: 0.875rem;
            li {
              padding: 0 1rem;
              label {
                padding: 0.625rem 0 0.625rem 0;
                border-bottom: 1px solid var(--cstm-storm-10);
                input {
                  margin-right: 0.25rem;
                }
              }
            }
            li:last-child label {
              padding-bottom: 0.25rem;
              border-bottom: none;
            }
          }
        }
      }
      &__datePicker {
        .rmdp-container {
          .rmdp-day-picker .rmdp-range {
            border-radius: 50%;
          }
          input {
            right: 60px !important;
          }
        }
        .custom-container-one {
          transform: none;
          padding: 0 0;
        }
        @media (min-width: 1600px) {
          .custom-container-one {
            padding: 0 13px;
          }
        }
      }
    }
    &__buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      width: 100%;
      margin-top: 0.5rem;
    }
  }
}
