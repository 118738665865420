.view-heatMap {
  display: flex;
  flex-direction: column;
  height: auto;
  padding-bottom: 30px;

  &__description {
    margin: 1.5rem 0;
    color: var(--cstm-oil-50);
    font-family: "yekanbakh";
    direction: rtl;
    text-align: justify;
    font-size: 0.875rem;
    overflow-wrap: anywhere;
    font-weight: 400;
  }
  &__analysis {
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    gap: 1rem;

    .fundamentalEntry {
      &-leftPart {
        min-height: 1.5rem;
      }
      .accordion {
        background-color: var(--cstm-color-base);
      }
    }
  }
  &__right-nav {
    &__accordion {
      padding: 0 !important;
      border-bottom: 1px solid var(--cstm-storm-30);

      span.total {
        margin-left: 0.25rem;
        color: var(--cstm-storm-key);
        font-weight: 300;
      }
      .analyzers {
        margin: 0.5rem 0 0;
        list-style: none;
        padding: 0;

        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 0.875rem;
          font-weight: 300;
          color: var(--cstm-storm-80);
          font-family: "roboto";
          margin: 1rem 0;
          span {
            font-size: 0.75rem;
            font-weight: 400;
            color: var(--cstm-storm-60);
          }
        }
        li:first-child {
          margin-top: 0;
        }
        li:last-child {
          margin-bottom: 0;
        }
      }
      button.new-notification {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid var(--cstm-sky-key);
        padding: 0.375rem 0.75rem;
        background-color: transparent;
        outline: none;
        border-radius: 2rem;
        font-size: 0.875rem;
        font-weight: 400;
        color: var(--cstm-sky-key);
      }
      .alerts {
        &__list {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          &__item {
            padding: 0.875rem;
            border-radius: 0.625rem;
            background-color: var(--cstm-storm-10);
            font-family: "roboto";
            &__header {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              &__date {
                display: flex;
                flex-direction: column;
                gap: 0.375rem;
                p {
                  margin: 0;
                  font-size: 0.75rem;
                  font-weight: 400;
                  display: flex;
                  align-items: center;
                  gap: 0.25rem;
                }
                &-validation {
                  color: var(--cstm-sky-70);
                }
                &-creation {
                  color: var(--cstm-oil-key);
                }
              }
              &__status {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                font-family: "robotoMed";
                font-size: 1rem;
                gap: 0.5rem;

                &-active {
                  color: var(--cstm-leaf-60);
                  svg path {
                    stroke: var(--cstm-leaf-60);
                  }
                }
                &-inActive {
                  color: var(--cstm-infrared-key);
                  svg path {
                    stroke: var(--cstm-infrared-key);
                  }
                }
              }
            }
            &__points {
              margin-top: 0.625rem;
              display: flex;
              flex-direction: column;
              gap: 0.375rem;
              &__item {
                display: flex;
                align-items: center;
                padding: 0.375rem 0.5rem;
                font-size: 0.875rem;
                border-radius: 1rem;
                background-color: var(--cstm-color-base);
                &__title {
                  font-family: "robotoMed";
                  &-ep {
                    color: var(--cstm-sky-key);
                  }
                  &-sl {
                    color: var(--cstm-infrared-key);
                  }
                  &-tp {
                    color: var(--cstm-leaf-50);
                  }
                }
                &__amount {
                  font-weight: 300;
                  color: var(--cstm-oil-60);
                  margin: 0 0.25rem 0 0.5rem;
                }
                .btn-ocean {
                  margin-left: 0.625rem;
                  margin-right: 0;
                  height: 1.5rem;
                  width: 3rem;
                  padding: 0px 0.5rem;
                }
              }
            }
            &__description {
              margin-top: 0.625rem;
              display: flex;
              flex-direction: column;
              align-items: center;
              p {
                margin: 0;
                padding: 0.5rem;
                font-size: 0.875rem;
                font-weight: 400;
                color: var(--cstm-storm-key);
                text-align: right;
                font-family: "yekanbakh";
                overflow-wrap: anywhere;
                width: 100%;
              }
              button {
                outline: none;
                border: none;
                background-color: transparent;
                color: var(--cstm-storm-50);
                font-size: 0.75rem;
                font-weight: 400;
                margin-top: 0.5rem;
                display: flex;
                align-items: center;
              }
            }
          }
        }
        &__empty-list {
          display: flex;
          p {
            padding: 0.75rem 0 0.5rem;
            margin: 0;
            font-family: "Roboto";
            font-size: 14px;
            text-align: left;
            color: var(--cstm-storm-key);
          }
        }
      }
    }
    &__accordion:last-child {
      border-bottom: none;
    }
    .accordion-close {
      padding: 1rem 0 !important;
    }
    .accordion-close:first-child {
      padding-top: 0 !important;
    }
  }
}
