.heatMapList {
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__addNew {
      background-color: var(--cstm-sky-80);
      border: none;
      outline: none;
      border-radius: 2rem;
      color: var(--cstm-color-base);
      padding: 0.5rem 0.75rem;
      display: flex;
      align-items: center;
      cursor: pointer;
      svg {
        path {
          stroke: var(--cstm-color-base);
        }
      }
      span {
        margin-left: 0.5rem;
      }
    }
  }
  .list-table-cstm-one__content-table {
    &__title {
      font-family: "yekanbakh";
    }
  }
}
.empty-heatMap {
  margin: auto;
  &__title {
    padding: 3rem 8rem;
    background-color: var(--cstm-color-base);
    border-radius: 0.625rem;
    font-family: "Roboto";
  }
}
