.fundamental-view {
  // border: 1px solid red;
  display: flex;
  flex-direction: column;
  height: auto;

  &__entries {
    margin-top: 1.5rem;
    .fundamentalEntry {
      .accordion {
        background-color: var(--cstm-color-base);
      }
      &-leftPart-label {
        color: var(--cstm-oil-60);
        font-family: "yekanbakh";
      }
      &-box{
        padding: 0 0.375rem;
      }
    }
  }
}
