.fundamental-preview {
  &__box {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    padding: 16px 0;

    &__title {
      color: var(--cstm-sky-70);
      margin: 2.5rem 0 0.75rem;
    }

    &__description {
      font-family: "yekanbakh";
      color: var(--cstm-oil-60);
      font-size: 1rem;
      font-weight: 400;
      text-align: right;
      overflow-wrap: anywhere;
    }

    &__entries {
      margin-top: 0.5rem;
      margin-bottom: 2rem;
      &__title {
        color: var(--cstm-sky-70);
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
      }
    }
    &__back-apply-btn {
      div {
        display: flex;
        justify-content: space-between;
        margin-top: 0.25rem;
        flex-direction: row;
        width: 100%;
        gap: 1rem;
      }
    }
  }
}
