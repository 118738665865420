body {
  padding: 0;
  margin: 0;

  font-family: "RobotoMed";
  overflow: hidden;
}

// svg {
//   path {
//     stroke: var(--cstm-storm-40);
//   }
// }

button {
  cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input:-webkit-autofill{
    -webkit-font-size: 40px;
} 