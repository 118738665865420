.technicalPointPreview {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  justify-content: space-between;
  padding: 0.625rem 0.75rem;
  border: 1px solid var(--cstm-storm-20);
  border-radius: 0.75rem;
  background-color: var(--cstm-color-base);
  font-family: "roboto";
  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__value {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      font-size: 0.875rem;
      color: var(--cstm-storm-70);

      &-ep {
        color: var(--cstm-sky-key);
        font-family: "robotoMed";
      }
      &-tp {
        color: var(--cstm-leaf-key);
        font-family: "robotoMed";
      }
      &-sl {
        color: var(--cstm-infrared-key);
        font-family: "robotoMed";
      }
    }
    &__note {
      &-show {
        background-color: var(--cstm-sky-10);
        color: var(--cstm-sky-key);
        svg path {
          stroke: var(--cstm-sky-key);
          stroke-width: 1px;
        }
      }
      &-hide {
        background-color: var(--cstm-sky-20);
        color: var(--cstm-sky-60);
        svg path {
          stroke: var(--cstm-sky-60);
          stroke-width: 1px;
        }
      }
      button {
        outline: none;
        border: none;
        border-radius: 2rem;
        padding: 0.5rem 0.625rem;
        font-size: 0.75rem;
        font-family: "roboto";
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.5rem;
      }
      button:disabled {
        background-color: var(--cstm-storm-10);
        color: var(--cstm-storm-40);
        cursor: default;
        svg path {
          stroke: var(--cstm-storm-40);
        }
      }

      &__tooltip {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        color: var(--cstm-oil-light);
        width: 14rem;
        font-family: var(--family-yekan-bakh);

        &__title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-family: "robotoMed";
          font-size: 1rem;
          border-bottom: 1px solid var(--cstm-storm-30);
          padding-bottom: 0.5rem;
        }
        &__description {
          padding: 0;
          margin: 0;
          overflow-wrap: anywhere;
          font-size: 0.875rem;
          line-height: 1.5;
        }
      }
    }
  }
  &__showNote {
    padding: 0;
    margin: 0;
    font-size: 0.875rem;
    color: var(--cstm-storm-key);
    overflow-wrap: anywhere;
    font-family: var(--family-yekan-bakh);
    text-align: right;
  }
}
