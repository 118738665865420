.login-cstm {
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  overflow: hidden;
  &__aside__box-form {
    width: 100%;
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    position: absolute;
    width: 85%;
    height: 100%;
  }
  aside {
    form {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
  &__aside {
    position: relative;
    height: 100%;
    width: 500px;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 40px;

    &__title {
      padding-top: 50px;
      height: 195px;

      h3 {
        font-family: var(--family-sunflower);
        color: var(--cstm-sky-80);
        font-weight: 100;
        letter-spacing: 1px;
      }
    }

    &__form {
      &__title {
        h4 {
          font-family: var(--family-roboto);
          color: var(--cstm-oil-60);
        }
      }
    }

    &__input {
      width: 100%;
      cursor: auto;
      height: 45px;

      display: flex;
      align-items: center;
      margin: 10px 0;

      &__title {
        width: fit-content;
      }

      &__title {
        font-size: 13px;
        top: -9px;
      }

      &__password {
        display: flex;
        align-items: center;

        cursor: pointer;
        svg {
          path {
            stroke: var(--cstm-storm-80);
          }
        }
      }
      input {
        border: none;
        outline: none;

        width: 80%;
        height: 5px;
        padding: 10px 8px;
        margin-left: 10px;
        margin: 2px 10px;
      }
    }
    &__captcha {
      margin: 0.625rem 0;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;
      gap: 0.5rem;
      p {
        padding: 0;
        margin: 0;
        font-family: "Roboto";
        font-size: 0.75rem;
      }
    }

    &__form-button {
      border: none;
      margin: 10px 0;
      padding: 13px;
      border-radius: 32px;

      background: var(--cstm-sky-key);
      color: var(--cstm-color-base);
    }

    &__form-button:disabled {
      cursor: auto;
      opacity: 0.7;
    }

    &__error {
      margin-bottom: 0;
      font-family: "Roboto";
      font-size: 14px;
      color: var(--cstm-infrared-key);
      text-align: center;
      overflow: auto;
    }
  }

  &__wallpaper {
    width: 100%;
    height: 100%;
    background-color: var(--cstm-sky-80);
    background-image: url("../../../../img/login.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
  }
}
