.fundamental-details {
  &__box {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;

    &__data-add {
      background: var(--cstm-storm-10);
      margin-top: 2rem;
      border-radius: 10px;
      padding: 1rem 10px;
      color: var(--cstm-sky-70);

      &__input {
        display: flex;
        align-items: center;
        font-size: 12px;
        justify-content: space-between;
        margin-top: 10px;

        &__title {
          color: var(--cstm-oil-80);
          padding: 5px;
        }
      }

      &__input-write {
        margin-top: 0.5rem;
        display: flex;
        padding: 11px 12px;
        gap: 6px;
        border-radius: 1rem;

        &__copy-icon {
          cursor: pointer;
          i {
            img {
              width: 16px;
            }
          }
        }

        &__datePicker {
          margin-top: 1rem;
        }

        &__title {
          font-size: 0.875rem;
          font-family: "RobotoMed";
          color: var(--cstm-oil-dark);
          margin-right: 2rem;
          display: flex;
          align-items: center;
          &__value {
            width: 100%;

            input {
              width: 100%;
              border: none;
              outline: none;
              text-align: right;
              font-family: "yekanbakh";
            }
          }
        }

        &__textarea {
          width: 100%;
          height: 40px;
          padding-right: 8px;

          textarea {
            width: 100%;
            height: 100%;
            padding-top: 0;

            border: none;
            outline: none;
            font-family: "yekanbakh";
            resize: none;
            font-size: 14px;

            text-align: right;
            color: var(--cstm-oil-60);
            direction: rtl;
            &::placeholder {
              text-align: left;
            }
          }
        }
      }

      &__add {
        display: flex;
        button {
          background: var(--cstm-sky-key);
          border: none;
          border-radius: 12px;
          color: var(--cstm-color-base);
          font-family: "Roboto";
          width: 100%;
          padding: 10px;
          margin-top: 1rem;
          cursor: pointer;
        }
        :disabled {
          cursor: auto;
          opacity: 0.7;
        }
      }
      &__warning {
        display: flex;
        align-items: center;
        color: var(--cstm-oil-50);
        font-family: "Roboto";
        font-size: 0.75rem;
        margin-top: 0.25rem;
        img {
          margin-right: 0.25rem;
        }
      }
      &__back-next-btn {
        margin-top: 3rem;
        div {
          display: flex;
          justify-content: space-between;
          margin-top: 0.25rem;
          flex-direction: row;
          width: 100%;
          gap: 1rem;
        }
      }
    }

    &__entries {
      margin-top: 20px;
      &__title {
        color: var(--cstm-sky-70);
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
      }
    }
  }
}
