.trading-view-chart-widget {
    // border: 1px solid royalblue;
    // height: 95%;
    width: 100%;

    .tradingview-widget-container {
        height: 100%;
        width: 100%
    }

    .tradingview-widget-container__widget {
        height: calc(100% - 32px);
        width: 100%
    }
}
