.load-wrap {
  width: 100%;
  height: auto;
  margin: auto;
  padding: 0.5rem;
  border-radius: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.load-wrap:last-child {
  margin-right: 0;
}

.load {
  .line {
    display: inline-block;
    border-radius: 15px;
    background-color: var(--cstm-sky-key);
    &-large {
      width: 1rem;
      height: 1rem;
    }
    &-medium {
      width: 0.75rem;
      height: 0.75rem;
    }
    &-small {
      width: 0.5rem;
      height: 0.5rem;
    }
  }
  &-iconButton {
    .line {
      background-color: var(--cstm-color-base);
    }
  }
}

.load .line-large:nth-last-child(1) {
  animation: loadingLarge 1.5s 1s infinite;
}
.load .line-large:nth-last-child(2) {
  animation: loadingLarge 1.5s 0.5s infinite;
}
.load .line-large:nth-last-child(3) {
  animation: loadingLarge 1.5s 0s infinite;
}

.load .line-medium:nth-last-child(1) {
  animation: loadingMedium 1.5s 1s infinite;
}
.load .line-medium:nth-last-child(2) {
  animation: loadingMedium 1.5s 0.5s infinite;
}
.load .line-medium:nth-last-child(3) {
  animation: loadingMedium 1.5s 0s infinite;
}

.load .line-small:nth-last-child(1) {
  animation: loadingSmall 1.5s 1s infinite;
}
.load .line-small:nth-last-child(2) {
  animation: loadingSmall 1.5s 0.5s infinite;
}
.load .line-small:nth-last-child(3) {
  animation: loadingSmall 1.5s 0s infinite;
}

@keyframes loadingLarge {
  0% {
    height: 1rem;
  }
  50% {
    height: 2rem;
  }
  100% {
    height: 1rem;
  }
}

@keyframes loadingMedium {
  0% {
    height: 0.75rem;
  }
  50% {
    height: 1.5rem;
  }
  100% {
    height: 0.75rem;
  }
}

@keyframes loadingSmall {
  0% {
    height: 0.5rem;
  }
  50% {
    height: 1rem;
  }
  100% {
    height: 0.5rem;
  }
}
