// [cstm] => custome nameClass for not infilit when added css librareis

//Button
.btn-cstm {
  border: none;
  padding: 8px;
  width: 100%;
  border-radius: 12px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  font-family: "Roboto";
}

.btn-cstm.btn-primary-cstm {
  background: var(--cstm-sky-key);
  color: var(--cstm-sky-light);
}
.btn-cstm.btn-primary-light-cstm {
  background: var(--cstm-sky-20);
  color: var(--cstm-sky-key);
}
.btn-cstm.btn-dark-cstm {
  background: var(--cstm-sky-80);
  color: var(--cstm-sky-light);
}

.btn-cstm.btn-dark-cstm:disabled {
  opacity: 0.7;
  cursor: auto;
}

.btn-cstm.btn-success-cstm {
  background: var(--cstm-twilight-30);
  color: var(--cstm-twilight-70);
}
.btn-cstm.btn-danger-cstm {
  background: var(--cstm-infrared-20);
  color: var(--cstm-infrared-40);
}
.btn-cstm.btn-outline-dark-cstm {
  border: 1px solid var(--cstm-sky-80);
  color: var(--cstm-sky-80);
  background: none;
}

.btn-ocean {
  background: var(--cstm-ocean-20);
  color: var(--cstm-ocean-key);
  padding: 0.25rem 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  min-width: fit-content;
  font-size: 12px;
  font-family: var(--family-roboto);
  cursor: default;
  font-weight: bold;
}
//Button

// Text

.text-primary-cstm {
  color: var(--cstm-sky-key);
}

.text-primary-cstm-1 {
  color: var(--cstm-sky-70);
}

.text-primary-cstm-2 {
  color: var(--cstm-sky-60);
}

.text-primary-cstm-3 {
  color: var(--cstm-sky-80);
}

.text-shadow-cstm-1 {
  color: var(--cstm-storm-20);
}

.text-shadow-cstm-2 {
  color: var(--cstm-storm-30);
}

.text-shadow-cstm-3 {
  color: var(--cstm-storm-40);
}

.text-shadow-cstm-4 {
  color: var(--cstm-storm-key);
}

.text-shadow-cstm-5 {
  color: var(--cstm-storm-dark);
}

.text-shadow-cstm-6 {
  color: var(--cstm-oil-20);
}

.text-shadow-cstm-7 {
  color: var(--cstm-oil-30);
}

.text-shadow-cstm-8 {
  color: var(--cstm-oil-40);
}

.text-shadow-cstm-9 {
  color: var(--cstm-oil-key);
}

.text-shadow-cstm-10 {
  color: var(--cstm-oil-dark);
}

// Text

.scrollbar-style::-webkit-scrollbar {
  width: 3px;
  background: var(--cstm-sky-20);
}
.scrollbar-style-thumb::-webkit-scrollbar-thumb {
  background-color: var(--cstm-sky-30);
}

.success-color {
  color: var(--cstm-leaf-50);
}

.danger-color {
  color: var(--cstm-infrared-50);
}
