@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

*,
::before,
::after {
  box-sizing: content-box;
}

button, input, optgroup, select, textarea{
  font-size: revert;
}
