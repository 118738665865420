.list-table-cstm-one {
  max-height: 74vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 0.25rem;
  margin-bottom: 1rem;
  &__content-table {
    border-collapse: separate;
    border-spacing: 0 12px;
    font-size: 1rem;
    width: 100%;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    text-align: center;
    font-family: var(--family-roboto);
    font-weight: 400;

    &__details-arrow {
      svg {
        cursor: pointer;
        border: 1px solid var(--cstm-sky-key);
        width: 15px;
        height: 15px;
        padding: 10px;
        border-radius: 100%;
        path {
          stroke: var(--cstm-sky-key);
        }
      }

      svg:hover {
        cursor: pointer;
        border: 1px solid var(--cstm-sky-key);
        background: var(--cstm-sky-key);
        path {
          stroke: var(--cstm-color-base);
        }
      }
    }
    &__date-time {
      gap: 5px;
      &__date {
      }
      &__time {
        font-size: 14px;
        color: var(--cstm-oil-50);
        font-weight: 100;
      }
    }
    &__analysis-type,
    &__position-type,
    &__user {
      width: fit-content;
      padding: 6px 10px;
      border-radius: 32px;

      font-weight: 400;
      margin: auto;
      font-size: 0.75rem;
    }
    &__position-type {
      &.short {
        background: var(--cstm-infrared-10);
        color: var(--cstm-infrared-50);
      }
      &.long {
        background: var(--cstm-twilight-30);
        color: var(--cstm-twilight-70);
      }
    }
    &__more {
      cursor: pointer;
    }
    &__flex {
      display: flex;
      justify-content: center;
      gap: 10px;
      width: fit-content;
      margin: 0 auto;
    }
    td:first-child,
    thead th:first-child {
      border-radius: 10px 0 0 10px;
    }
    td:last-child,
    thead th:last-child {
      border-radius: 0 10px 10px 0;
    }

    thead tr {
      background-color: var(--cstm-sky-20);
      color: var(--cstm-storm-70);
      text-align: left;
      font-weight: bold;
    }

    th {
      font-family: var(--family-roboto);
    }

    th,
    td {
      padding: 19px 15px;
      text-align: center;
      font-family: var(--family-roboto);
    }
    td {
      padding: 8px 15px;
    }

    tbody tr {
      vertical-align: middle;
    }

    tbody tr:nth-of-type(odd) {
      background-color: var(--cstm-sky-10);
    }
    tbody tr:nth-of-type(even) {
      background-color: var(--cstm-color-base);
    }
  }
}
td.list-table-cstm-one__content-table__flex {
  padding-top: 1rem;
}

.empty-analysis {
  margin: auto;
  &__title {
    padding: 3rem 8rem;
    background-color: var(--cstm-color-base);
    border-radius: 0.625rem;
    font-family: "Roboto";
  }
}
