:root {
  --cstm-color-base: #ffffff;
  --cstm-popup-black: #000000;
  --cstm-popup-box: #1d262b99;

  --cstm-storm-light: #fcfdfd;
  --cstm-storm-10: #f6f8f9;
  --cstm-storm-20: #e0e7eb;
  --cstm-storm-30: #c2cfd6;
  --cstm-storm-40: #9ab0bc;
  --cstm-storm-key: #668899;
  --cstm-storm-50: #54707d;
  --cstm-storm-60: #415762;
  --cstm-storm-70: #2f3f46;
  --cstm-storm-80: #1d262b;
  --cstm-storm-dark: #0a0e0f;

  --cstm-sky-light: #fafcff;
  --cstm-sky-10: #f0f7ff;
  --cstm-sky-20: #cce4ff;
  --cstm-sky-30: #99caff;
  --cstm-sky-40: #57a8ff;
  --cstm-sky-key: #007bff;
  --cstm-sky-50: #0065d1;
  --cstm-sky-60: #004fa3;
  --cstm-sky-70: #003975;
  --cstm-sky-80: #002247;
  --cstm-sky-dark: #000c19;

  --cstm-twilight-light: #f9fefd;
  --cstm-twilight-10: #f2fcfc;
  --cstm-twilight-20: #d4f7f4;
  --cstm-twilight-30: #aaeee9;
  --cstm-twilight-40: #72e3da;
  --cstm-twilight-key: #2ad5c6;
  --cstm-twilight-50: #23afa3;
  --cstm-twilight-60: #1b887f;
  --cstm-twilight-70: #13625c;
  --cstm-twilight-80: #0c3c37;
  --cstm-twilight-dark: #041413;

  --cstm-ultraviolet-light: #fdfaff;
  --cstm-ultraviolet-10: #f7f0fe;
  --cstm-ultraviolet-20: #e6cffc;
  --cstm-ultraviolet-30: #cc9efa;
  --cstm-ultraviolet-40: #ab5ff6;
  --cstm-ultraviolet-key: #7f0df1;
  --cstm-ultraviolet-50: #680ac6;
  --cstm-ultraviolet-60: #52089b;
  --cstm-ultraviolet-70: #3b066f;
  --cstm-ultraviolet-80: #240344;
  --cstm-ultraviolet-dark: #0d0118;

  --cstm-sun-light: #fffefa;
  --cstm-sun-10: #fefaef;
  --cstm-sun-20: #fbf0d0;
  --cstm-sun-30: #f7e2a1;
  --cstm-sun-40: #f2cf63;
  --cstm-sun-key: #ebb513;
  --cstm-sun-50: #c0940f;
  --cstm-sun-60: #96740c;
  --cstm-sun-70: #6c5309;
  --cstm-sun-80: #423305;
  --cstm-sun-dark: #171202;

  --cstm-infrared-light: #fef9fa;
  --cstm-infrared-10: #fdf0f3;
  --cstm-infrared-20: #f9d2d9;
  --cstm-infrared-30: #f2a6b3;
  --cstm-infrared-40: #ea6c80;
  --cstm-infrared-key: #df2040;
  --cstm-infrared-50: #b71a34;
  --cstm-infrared-60: #8f1428;
  --cstm-infrared-70: #650e1d;
  --cstm-infrared-80: #3d0912;
  --cstm-infrared-dark: #160306;

  --cstm-leaf-light: #fbfdfc;
  --cstm-leaf-10: #f4fbf6;
  --cstm-leaf-20: #d9f2e1;
  --cstm-leaf-30: #b2e6c3;
  --cstm-leaf-40: #81d59d;
  --cstm-leaf-key: #40bf6a;
  --cstm-leaf-50: #349d57;
  --cstm-leaf-60: #297a44;
  --cstm-leaf-70: #1d5830;
  --cstm-leaf-80: #12361e;
  --cstm-leaf-dark: #06130b;

  --cstm-ocean-light: #f9fbfe;
  --cstm-ocean-10: #f3fafc;
  --cstm-ocean-20: #d6f0f5;
  --cstm-ocean-30: #ade0eb;
  --cstm-ocean-40: #78cddd;
  --cstm-ocean-key: #33b3cc;
  --cstm-ocean-50: #2a93a7;
  --cstm-ocean-60: #217283;
  --cstm-ocean-70: #17525e;
  --cstm-ocean-80: #0e3239;
  --cstm-ocean-dark: #051113;

  --cstm-wood-light: #fefbf9;
  --cstm-wood-10: #fcf7f3;
  --cstm-wood-20: #f5e3d6;
  --cstm-wood-30: #ebc7ad;
  --cstm-wood-40: #dda278;
  --cstm-wood-key: #cc7333;
  --cstm-wood-50: #a75e2a;
  --cstm-wood-60: #834a21;
  --cstm-wood-70: #5e3417;
  --cstm-wood-80: #39200e;
  --cstm-wood-dark: #140b05;

  --cstm-fire-light: #fffdfa;
  --cstm-fire-10: #fff7f0;
  --cstm-fire-20: #fee5cd;
  --cstm-fire-30: #fccc9c;
  --cstm-fire-40: #fbab5b;
  --cstm-fire-key: #f87f06;
  --cstm-fire-50: #cc6805;
  --cstm-fire-60: #9b5108;
  --cstm-fire-70: #723a03;
  --cstm-fire-80: #462302;
  --cstm-fire-dark: #190d01;

  --cstm-oil-light: #fdfdfd;
  --cstm-oil-10: #f7f7f7;
  --cstm-oil-20: #e6e6e6;
  --cstm-oil-30: #cccccc;
  --cstm-oil-40: #ababab;
  --cstm-oil-key: #808080;
  --cstm-oil-50: #696969;
  --cstm-oil-60: #525252;
  --cstm-oil-70: #3b3b3b;
  --cstm-oil-80: #242424;
  --cstm-oil-dark: #0d0d0d;

  --cstm-soil-light: #fdfcfb;
  --cstm-soil-10: #faf8f5;
  --cstm-soil-20: #eee7dd;
  --cstm-soil-30: #decfba;
  --cstm-soil-40: #c8b08d;
  --cstm-soil-key: #ac8753;
  --cstm-soil-50: #8d6f44;
  --cstm-soil-60: #6e5635;
  --cstm-soil-70: #4f3e26;
  --cstm-soil-80: #302617;
  --cstm-soil-dark: #110d08;

  --family-roboto: "Roboto";
  --family-roboto-med: "RobotoMed";
  --family-yekan-bakh: "yekanBakh";
  --family-sunflower: "Sunflower";
  --family-shabnam: "Shabnam";
}
