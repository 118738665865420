

@keyframes delayDisplayNone {
    from {

    }

    to {
        display: none;
    }
}

.delay-display-none {
    animation: delayDisplayNone forwards;
}

.rotate-180deg-sharp {
    transform: rotate(180deg);
}