.fundamentalEntry {
  display: flex;
  flex-direction: column;
  align-items: normal;

  .accordion {
    background-color: var(--cstm-storm-10);
    padding: 0 0.875rem;
    border-radius: 0.625rem;
  }

  &-rightPart {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-arrows {
      border-radius: 100%;
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 0.5rem;
      cursor: pointer;
    }
  }
  &-leftPart {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.25rem;
    font-family: "yekanbakh";

    svg {
      padding-right: 0.25rem;
    }
    &-label {
      color: #000;
      font-size: 0.875rem;
      font-weight: 700;
    }
    &-date {
      font-size: 0.875rem;
      color: var(--cstm-storm-50);
    }
    &-analyser {
      padding: 0.375rem 0.5rem;
      background-color: var(--cstm-storm-20);
      border-radius: 1.25rem;
      font-size: 0.75rem;
      font-weight: 300;
      color: var(--cstm-storm-50);
      font-family: "roboto";
      line-height: 1rem;
    }
  }
  &-box {
    padding: 0.375rem;
    background-color: var(--cstm-color-base);
    border-radius: 0.625rem;
    color: var(--cstm-storm-key);
    font-size: 0.875rem;
    text-align: right;
    font-family: "yekanbakh";
    overflow-wrap: anywhere;
  }
  button {
    background: none;
    outline: none;
    padding: 0;
    border: none;
    display: flex;
    cursor: pointer;
    align-items: center;
  }
}
