.main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__router {
    background: var(--cstm-oil-10);
    height: 100%;
    display: flex;
    flex-direction: column;

    &__container {
      height: 100%;
      display: flex;
      position: relative;
      justify-content: space-between;

      &__view-box-container {
        width: 67%;
        position: relative;
        margin: 70px auto 2rem;
        height: 90%;
        overflow-y: auto;
        padding-right: 0.5rem;
      }

      &__list-box-container {
        width: 93%;
        position: relative;
        margin: 0 auto;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        button.filterBtn {
          width: max-content;
          display: flex;
          align-items: center;
          padding: 0.75rem;
          background-color: var(--cstm-sky-light);
          color: var(--cstm-sky-key);
          border: none;
          outline: none;
          border-radius: 2rem;
          font-weight: 500;
          font-size: 14px;
          svg {
            margin-left: 0.5rem;
            path {
              stroke: var(--cstm-sky-key);
            }
          }
          img {
            margin-right: 0.5rem;
          }
          span {
            border-radius: 2rem;
            background-color: var(--cstm-sky-20);
            color: var(--cstm-sky-key);
            margin-left: 0.75rem;
            padding: 1px 0.25rem;
          }
        }
      }

      &__line-chart {
        width: 70%;
        height: 90%;
        margin: 0 auto;
        margin-top: 30px;
      }
    }
  }
}
@media screen and (min-width: 1440px) and (max-width: 1600px) {
  .main__router__container__view-box-container {
    width: 63%;
  }
}
