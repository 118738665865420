.nav {
  width: 300px;
  position: relative;
  background: var(--cstm-storm-light);
  width: 300px;

  &.isClose {
    width: 90px;

    h2 {
      opacity: 0;
    }
  }

  &__list {
    height: 100%;
    ul {
      list-style: none;
      height: 100%;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h2 {
        font-weight: normal;
        font-family: "Sunflower";
        color: var(--cstm-sky-80);
        // transition: $transition-03;
        margin: 0;
        padding-left: 1rem;
        font-size: 1.5rem;
      }
    }
    li {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding-left: 20px;

      i {
        display: flex;
        align-items: center;
        flex-direction: column;
        img {
          width: 25px;
        }

        svg {
          path {
            stroke: var(--cstm-sky-key);
          }
        }
      }
      svg {
        path {
          stroke: var(--cstm-sky-key);
        }
      }

      span {
        color: var(--cstm-storm-key);
      }
    }

    &__item {
      position: relative;
      max-height: 35px;
      overflow: hidden;
      &.listOpen {
        background: var(--cstm-storm-10);
        max-height: 1000px;
        overflow: unset;

        &.mainItemActive {
          border-left: 2px solid var(--cstm-sky-key);
          a span{
            color: var(--cstm-sky-key);
          } 
        }
      }
      &.itemClose {
        padding-top: 0;
        padding-bottom: 8px;
        margin-top: 0rem;
      }
      &.isActiveClose {
        background: var(--cstm-sky-20);
        border-right: 3px solid var(--cstm-sky-key);
      }
      &__sub-category {
        background: var(--cstm-storm-10);
        &__title {
          padding: 13px 0 10px 28px !important;
        }

        &.sub-ctgy-mode-2 {
          position: absolute;
          
          right: -3px;
          transform: translateX(100%); 
          

          top: 1px;
          z-index: 1000;
          box-shadow: 3px 2px 4px 0px var(--cstm-storm-20);

          ul {
            a.active {
              border-left: none;
            }
          }
        }
        ul {
          a {
            text-decoration: none;
          }
          a.active {
            border-left: 2px solid var(--cstm-sky-key);
            li {
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 10px 35px;
            }

            i {
              svg {
                path {
                  stroke: var(--cstm-sky-key);
                }
              }
            }
            span {
              color: var(--cstm-sky-key);
              font-size: 13px;
            }
          }
          li {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 35px;

            i {
              svg {
                path {
                  stroke: var(--cstm-storm-40);
                }
              }
            }
            span {
              color: var(--cstm-storm-40);
              font-size: 13px;
              width: max-content;
            }
          }
        }
      }
     
      &__box {
        padding: 10px 0px 0px 15px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__title {
          display: flex;
          align-items: center;
          gap: 15px;
        }

        &__icon {
        }
        &__arrow-icon {
          cursor: pointer;
          &.arrow-rotate-left {
            transform: rotate(-90deg);
          }
        }
      }
    }

    &__box-span {
      width: 150px;
    }

    &__title {
      width: 190px;
      margin-top: 8px;
      margin-bottom: 0px;
      &.isClose {
        i {
          img {
            right: 26px;
          }
        }
      }
      i {
        img {
          padding-left: 10px;
          position: absolute;
          right: 8px;
          top: 20px;
          font-size: 10px;
          width: 25px !important;
        }
      }
     
    }

   

    &__box-list-1 {
      li {
        padding: 10px 15px 0px 0px;
      }
      img {
        cursor: pointer;
      }
    }

    &__box-list-2 {
      padding-bottom: 15px;
      li {
        padding: 10px 15px 0px 0px;
      }
    }
  }
}
