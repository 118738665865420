.comment {
  margin: 2.5rem 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  &__header {
    padding: 0 0.625rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    h4 {
      display: flex;
      align-items: center;
      span {
        margin-left: 0.25rem;
        font-size: 0.875rem;
        font-weight: 300;
      }
    }
    .btn-cstm {
      width: auto;
      padding-right: 1.875rem;
      padding-left: 1.875rem;
    }
  }
  &__list {
    padding: 0 0.625rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    ul {
      padding-inline-start: 0;
      width: 100%;
      margin: 6px 0 0;
      max-height: 265px;
      overflow-y: auto;
    }



    margin: 0;
    &__item {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding: 1rem 0;

      list-style: none;
      font-family: "yekanbakh";
      font-size: 0.875rem;
      color: var(--cstm-oil-50);

      &__title {
        display: flex;
        align-items: center;
        margin-bottom: 0.625rem;

        img {
          height: 1.5rem;
          width: 1.5rem;
        }
        p {
          margin: 0 0 0 0.625rem;
          color: var(--cstm-sky-50);
        }
        span {
          margin: 0 0 0 0.625rem;
          font-size: 0.75rem;
          color: var(--cstm-storm-60);
          font-weight: 300;
        }
      }
  
    }
    :last-child {
      border-bottom: none;
    }
  }
  &__loadMore {
    padding: 0 0.625rem;
    margin: 1.5rem auto;
    border-radius: 2rem;
    background-color: var(--cstm-storm-20);
    color: var(--cstm-storm-key);
    padding: 0.5rem 1.25rem;
    font-family: "RobotoMed";
    border: none;
  }
  &__newComment {
    padding: 1rem 0.625rem;
    height: 4.75rem;
    display: flex;
    border-radius: 0.625rem;
    margin: 1.5rem 0;

    &__show {
      background-color: var(--cstm-storm-light);
      display: flex;
      flex-direction: column;
      justify-content: center;

      h4,
      .messageInput {
        display: flex;
      }
    }
    &__hide {
      h4,
      .messageInput {
        display: none;
      }
    }
  }
  h4 {
    font-family: "Roboto";
    color: var(--cstm-storm-70);
    margin: 0;
    border-radius: 2rem;
  }
  &__emptyList {
    &__title {
      padding: 1.25rem 0;
      text-align: center;
    }
  }
}
