.profile {
  padding: 3rem 1rem;
  background-color: var(--cstm-color-base);
  border-radius: 1.25rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 23%;
  &__avatar {
    padding: 1.125rem;
    background-color: var(--cstm-sky-20);
    border-radius: 100%;
    border: 2px solid var(--cstm-sky-80);
    display: flex;
    img {
      width: 2.5rem;
    }
  }
  &__email {
    margin-top: 1rem;
    font-size: 0.875rem;
    font-weight: 400;
    font-family: "Roboto";
    color: var(--cstm-oil-dark);
  }
  &__form {
    display: flex;
    gap: 1.25rem;
    flex-direction: column;
    margin-top: 2.5rem;
    width: 100%;
    .select-cstm-one {
      margin: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 12px;
      &__title {
        font-size: 0.875rem;
        font-weight: 400;
        top: -10px;
      }
      &__passwordIcon {
        border: none;
        outline: none;
        background: transparent;
        padding: 0;
        margin: 0;
        svg path {
          stroke: var(--cstm-sky-40);
        }
      }
      input {
        width: 90%;
        border: none;
        padding: 0.75rem 0.75rem 0.75rem 0;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.6;
        color: var(--cstm-oil-dark);
      }
      input:focus-visible {
        border: none;
        outline: none;
      }
    }
    &__submit {
      width: 45%;
      margin: 3rem auto 0;
    }
  }
}
