.view-data-header-cstm {
  display: flex;
  flex-direction: column;

  &__date-time {
    color: var(--cstm-storm-60);
    font-family: var(--family-roboto);
  }

  &__info {
    background: var(--cstm-color-base);
    height: 60px;
    border-radius: 10px;
    margin: 15px 0;
    display: flex;
    justify-content: space-between;

    &__left {
      display: flex;
      align-items: center;
      width: fit-content;
      position: relative;
      gap: 0.5rem;

      &__name {
        padding: 0 8px;
        color: var(--cstm-storm-80);
        font-size: 20px;
      }

      &__time-frame {
        font-size: 18px;
        color: var(--cstm-storm-50);
        font-family: var(--family-roboto-med);
      }
      &__type {
        font-size: 18px;
        color: var(--cstm-storm-50);
        font-family: var(--family-roboto-med);
        color: var(--cstm-sky-60);
        display: flex;
        align-items: center;
      }

      &__position-type {
        font-family: var(--family-roboto);
        padding: 6px 15px;
        border-radius: 20px;
        font-weight: bold;
        &.long {
          color: var(--cstm-twilight-70);
          background: var(--cstm-twilight-30);
        }
        &.short {
          color: var(--cstm-infrared-40);
          background: var(--cstm-infrared-10);
        }
      }
    }
    &__right {
      width: fit-content;
      display: flex;
      align-items: center;
      gap: 12px;
      color: var(--cstm-sky-70);
      font-family: var(--family-roboto);
      font-size: 18px;
      padding-right: 20px;
      svg {
        path {
          stroke: var(--cstm-sun-key);
          fill: var(--cstm-sun-key);
        }
      }
    }
  }

  &__analysisName {
    margin: 0.5rem 0;
    font-size: 1.125rem;
    color: var(--cstm-oil-60);
    text-align: right;
    font-weight: bold;
    font-family: "yekanbakh";
  }

  &__description {
    margin: 0.5rem 0 1.5rem;

    p {
      color: var(--cstm-oil-60);
      font-family: "yekanbakh";
      direction: rtl;
      text-align: right;
      font-size: 16px;
      margin: 0;
      overflow-wrap: anywhere;
    }
  }
}
