
@font-face {
    font-family: "Roboto";
    src: url("./Roboto/Roboto-Light.woff2") format("woff2"),
        url("./Roboto/Roboto-Light.woff") format("woff"),
        url("./Roboto/Roboto-Light.ttf") format("ttf");
}

@font-face {
    font-family: "RobotoMed"; 
    src: url("./RobotoMed/Roboto-Medium.woff2") format("woff2"),
        url("./RobotoMed/Roboto-Medium.woff") format("woff"),
        url("./RobotoMed/Roboto-Medium.ttf") format("ttf");
}

@font-face {
    font-family: "Sunflower";
    src: url("./Sunflower/Sunflower-Light.woff2") format("woff2"),
        url("./Sunflower/Sunflower-Light.woff") format("woff"),
        url("./Sunflower/Sunflower-Light.ttf") format("ttf");
}

// FARSI 
@font-face {
    font-family: "yekanbakh";
    src: url("./yekanbakh/YekanBakh-Light.woff2") format("woff2"),
        url("./yekanbakh/YekanBakh-Light.woff") format("woff"),
        url("./yekanbakh/YekanBakh-Light.ttf") format("ttf");
}

@font-face {
    font-family: "Shabnam";
    src: url("./shabnam/Shabnam.woff2") format("woff2"),
        url("./shabnam/Shabnam.woff") format("woff"),
        url("./shabnam/Shabnam.ttf") format("ttf");
}

// and more ... 